import { ProductInfo } from "../Models/CustomerBasketItem";


export class Cost
{
    constructor(
        public Label: string, 
        public Value: number)
    { }
}
