import { Collection } from "@tblabs/truffle";
import { RawPayment } from "./RawPayment";
import { OrdersApiRawBasketItem } from "./RawBasketItem";
import { RawHuman } from "./RawHuman";
import { RawTransit } from "./RawTransit";
import { RawTraining } from "./RawTraining";


export class OrderMeta
{
    public Created = new Date(0);
    public CreatedBy: string = "";
}
export class OrdersApiPackage
{
    public Payment = new Collection<Partial<RawPayment>>();
    public Basket = new Collection<Partial<OrdersApiRawBasketItem>>();
    public People = new Collection<Partial<RawHuman>>();
    public Transit = new Collection<Partial<RawTransit>>();
    public Training = new Collection<Partial<RawTraining>>();
    public Meta = new OrderMeta();
    
    constructor(public Id: string)
    { 
        this.Meta.Created = new Date();
        this.Meta.CreatedBy = window.location.hostname;
    }
}
