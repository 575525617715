import { ProductInfo } from "../Models/CustomerBasketItem";



export class SummaryBasketItem
{
    public Type = "";
    public Product: ProductInfo;
    public Cost = (-1);
    public Quantity = 1;
    public RentStart: Date;
    public RentEnd: Date;
}
