import { RefSerializer } from "@tblabs/truffle";
import { DeliveryForm } from "../Models/DeliveryForm";
import { ReturnForm } from "../Models/ReturnForm";
import { Cost } from "./Cost";
import { Customer } from "./Customer";
import { Discount } from "./Discount";
import { PaymentForm } from "./PaymentForm";
import { ReturnableCost } from "./ReturnableCost";
import { SummaryBasketItem } from "./SummaryBasketItem";



export class BasketSummary
{
    public OrderId: string = "";
    public Costs: (Cost | ReturnableCost | Discount)[] = [];
    public BasketItems: SummaryBasketItem[] = [];
    public Customer = new Customer();
    public PaymentForm: PaymentForm = PaymentForm.Unset;
    public DeliveryForm: DeliveryForm = DeliveryForm.Unset;
    public ReturnForm: ReturnForm = ReturnForm.Unset;

    public get AsPackage()
    {
        return RefSerializer.Flatenize(this);
    }

    public Clear()
    {
        this.Costs = [];
    }

    public get Sum(): number
    {
        return this.Costs.filter(x => x instanceof Cost || x instanceof ReturnableCost).reduce((p, c) => p + c.Value, 0);
    }

    public get ToReturn(): number
    {
        return this.Costs.filter(x => x instanceof ReturnableCost).reduce((p, c) => p + c.Value, 0);
    }
    public Add(x: Cost | ReturnableCost | Discount, product?: SummaryBasketItem): void
    {
        this.Costs.push(x);

        if (product)
            this.BasketItems.push(product);
    }
    public get NonReturnableCosts(): Cost[]
    {
        return this.Costs.filter(x => x instanceof Cost);
    }
    public get ReturnableCosts(): Cost[]
    {
        return this.Costs.filter(x => x instanceof ReturnableCost);
    }
    public get NonReturnableCostsSum(): number
    {
        return this.NonReturnableCosts.reduce((p, c) => p + c.Value, 0);
    }
    public get ReturnableCostsSum(): number
    {
        return this.ReturnableCosts.reduce((p, c) => p + c.Value, 0);
    }
}
