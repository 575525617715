import { RefString, RefNumber, TableBuilder, Div, Image, Span, Link, Select, TextInput, Line, Button, Ref, RefDate, Label, Radio, DestroyingContentSwitcher, RefBool } from "@tblabs/truffle";
import { Center } from "./Utils/Center";
import moment from "moment";
import { DatesRange, DatesRangeInput } from "./DatesRange";
import { DeliveryForm, DeliveryFormValueToLabel } from "../Models/DeliveryForm";
import { ReturnForm, ReturnFormValueToLabel } from "../Models/ReturnForm";
import { InputRow } from "./InputRow";
import { Section } from "./Section";
import { Basket } from "../Services/Basket/Basket";
import { WebsiteContent } from "../Models/WebsiteContent";
import { PlusMinusInput } from "./PlusMinusInput";
import { HashLink } from "./Utils/HashLink";
import { DynamicBasket } from "./DynamicBasket";
import { Markdown } from "./Utils/Markdown";
import { Table } from "@tblabs/truffle/Controls/TableBuilder/Table";
import { RefWatcher } from "./RefWatcher";
import { BasketItemType, PaymentForm } from "./PaymentForm";
import { Cost } from "./Cost";
import { ReturnableCost } from "./ReturnableCost";
import { Discount } from "./Discount";
import { Loader } from "./Loader";
import { v4 as uuidv4 } from 'uuid';
import { RawRentBasketItem, RentBasketItem } from "../Models/CustomerBasketItem";
import { TrainingStatus } from "./TrainingStatus";
import { TrainingType } from "./TrainingType";
import { ValidableTextInput } from "./ValidableTextInput";
import { HumanType } from "./HumanType";
import { OrdersApiPackage } from "./OrdersApiPackage";
import { TransitDirection } from "./TransitDirection";
import { TransitType } from "./TransitType";
import { OrdersApiRawBasketItem, OrdersApiRawRentOrder } from "./RawBasketItem";
import { OrderBasketItemStatus } from "./OrderBasketItemStatus";
import { DangerButton } from "./DangerButton";
import { PrimaryButton } from "./PrimaryButton";
import { PaymentType } from "./PaymentType";
import { OrderRepo } from "../Services/Repo/RawWebsiteContentRepo";
import { TransitStatus } from "./TransitStatus";
import { PaymentStatus } from "./PaymentStatus.1";
import { SummaryBasketItem } from "./SummaryBasketItem";
import { BasketSummary } from "./BasketSummary";


enum OrderFormView
{
    Form,
    Summary,
    Sent,
    SendingFault,
}

export class OrderForm extends Div
{
    constructor(content: WebsiteContent, private basket: Basket, private _repo: OrderRepo)
    {
        super();

        //#region vars
        const customerName = new RefString().Storable("customer-name")
        const customerPhone = new RefString().Storable("customer-phone")
        const customerEmail = new RefString().Storable("customer-email")
        const customerAddress = new RefString().Storable("customer-address")
        const customerExp = new RefNumber().Storable("customer-exp")
        const deliveryForm = new Ref<DeliveryForm>(DeliveryForm.Unset).Storable("delivery-form")
        const returnForm = new Ref<ReturnForm>(ReturnForm.Unset).Storable("return-form")
        const paymentForm = new Ref<PaymentForm>(PaymentForm.Unset).Storable("payment-form")
        const start = new RefDate(moment(new Date()).hour(12).minute(0).toDate()).Storable("rent-from")
        const end = new RefDate(moment(new Date()).hour(12).minute(0).toDate()).Storable("rent-to")
        const rentDatesRange = new DatesRange(start, end)
        const dynamicBasket = new DynamicBasket(content, basket, rentDatesRange)
        const summary = new BasketSummary()
        const view = new Ref<OrderFormView>(OrderFormView.Form)
        const depositInfo = new RefString()
        const depositReturnInfo = new RefString()
        const trainingInfo = new RefString()
        const allowCashPayment = new RefBool()
        const showCustomerDepositAccountInput = new RefBool()
        const place = new RefString("Warszawa/Wola");
        //#endregion

        // 🧠
        RefWatcher.Watch([deliveryForm, paymentForm, returnForm, dynamicBasket.Items], () =>
        {
            const personalDelivery = [DeliveryForm.PersonalWithTraining, DeliveryForm.PersonalWithoutTraining].includes(deliveryForm.value);
            const personalReturn = [ReturnForm.Personal].includes(returnForm.value);

            // 🎓 Training
            trainingInfo.value = {
                [DeliveryForm.Pocztex]: `Szkolenie odbędzie się przez telefon. Otrzymasz także materiały do samodzielnej nauki`,
                [DeliveryForm.PersonalWithTraining]: `Szkolenie odbędzie się na miejscu. Otrzymasz także materiały do samodzielnej nauki`,
                [DeliveryForm.PersonalWithoutTraining]: `Szkolenie nie odbędzie się na miejscu. Zamiast tego otrzymasz materiały do samodzielnej nauki oraz dostęp do darmowej infolinii`,
            }[deliveryForm.value] || "⚠️ Błąd formularza!";

            // Package + Cash is not possible, so switch to the next available option
            if (deliveryForm.Is(DeliveryForm.Pocztex) && paymentForm.Is(PaymentForm.Cash))
                paymentForm.value = PaymentForm.DepositPrepaidServicePostpaid;
            // Nothing to rent + Personal delivery + Cash at delivery is not possible, so switch to the next available option
            if (!basket.IsAnythingToRent && !deliveryForm.Is(DeliveryForm.Pocztex) && paymentForm.Is(PaymentForm.CashAtDelivery))
                paymentForm.value = PaymentForm.Cash;

            // 💳 Payment
            allowCashPayment.value = personalDelivery;

            // 💰 Deposit
            depositInfo.value = {
                [PaymentForm.Cash]: `Kaucja zostanie pobrana gotówką podczas odbioru sprzętu`,
                [PaymentForm.DepositPrepaidServicePostpaid]: `Łączna suma kaucji wynosi ${dynamicBasket.GetDepositsSum()}zł. Płatne z góry przelewem`,
                [PaymentForm.PartialDepositPrepaidServicePostpaid]: `Łączna suma kaucji wynosi ${dynamicBasket.GetDepositsSum()}zł, z czego 60zł jest płatne z góry przelewem, pozostała kwota kaucji zostanie doliczona do kwoty pobrania`,
                [PaymentForm.FromPreviousOrder]: `Wykorzystamy kaucję z Twojego poprzedniego zamówienia o ile to możliwe (wartość kaucji jest taka sama i poprzednie zamówienie nie zostało jeszcze rozliczone)`,
                [PaymentForm.Crypto]: `Kaucja zostanie wliczona w koszt przelewu`,
                [PaymentForm.Transfer]: `Kwota kaucji zostanie doliczona do kwoty przelewu`
            }[paymentForm.value] || "⚠️ Błąd formularza!";

            // 💰 Deposit return
            showCustomerDepositAccountInput.value = personalDelivery && !personalReturn && paymentForm.Is(PaymentForm.Cash);

            depositReturnInfo.value = {
                [PaymentForm.Cash]: `Kaucja zostanie zwrócona na miejscu w gotówce`,
                [PaymentForm.Transfer]: `Kaucja zostanie zwrócona na konto, z którego przyszła`,
                [PaymentForm.DepositPrepaidServicePostpaid]: `Kaucja zostanie odesłana na konto, z którego przyszła`,
                [PaymentForm.PartialDepositPrepaidServicePostpaid]: `Kaucja zostanie zwrócona w dwóch przelewach`,
                [PaymentForm.Crypto]: `Kaucja zostanie zwrócona na wskazany portfel (wyliczona z proporcji)`,
                [PaymentForm.FromPreviousOrder]: `Jako że kaucja została opłacona w poprzednim zamówieniu - zostanie zwrócona w sposób zgodny z formą jej opłacenia`,
            }[paymentForm.value] || "⚠️ Błąd formularza!";
        });

        // 📺
        this.Append(
            new DestroyingContentSwitcher(view)
                .AddContent(OrderFormView.Form, () => new Div().Append(
                    new Section("🗓️ Okres wypożyczenia", [
                        new DatesRangeInput(rentDatesRange)
                    ]).Visible(this.basket.IsAnythingToRentRef, "block"),
                    new Section("🛒 Twoje zamówienie", [
                        new TableBuilder(dynamicBasket.Items)
                            .AddColumn("Usługa", p => [
                                new Label(p.Label),
                            ])
                            .AddColumn("Produkt", p => [
                                new Image(p.Product.Picture).Height(44).Width(66).Margin(8).FloatLeft(),
                                new Div().TextAlignLeft().FloatLeft().MarginTop(8).Append(
                                    new Div().Append(
                                        new Link(p.Product.Name, p.Product.Link),
                                    ),
                                    new Span(p.Product.Price).Color("#999").FontSize(12)
                                ),
                            ])
                            .AddColumn("Ilość", p => [
                                new PlusMinusInput(p.Quantity).MarginBottom(0),
                                new Label(p.DiscountLabel).FontSize(14).MarginTop(0)
                            ])
                            .AddColumn("Koszt", p => new Label(p.Cost, v => v + " zł").Bold().DisplayInlineBlock().Width(70))
                            .AddColumn("", (x, row) => 
                            {
                                row.TextAlignLeft()
                                return [
                                    new Link("❌ Usuń").NoDecorationOnHover().OnClick(() => dynamicBasket.Remove(x)),
                                ]
                            })
                            .ForEachRow(row => row.Background("#fafafa").MarginLeftRight(12))
                            .WhenEmpty(new Center("Brak produktów").MarginTopBottom(32))
                            .Build().WidthAuto().MarginAuto(),
                        new Center(
                            new HashLink("➕ Dodaj produkty").Color("#1e87f0")
                        ).MarginTop(20),
                    ]),
                    new Section("🚚 Odbiór / dostawa", [
                        new InputRow("Forma",
                            new Radio(deliveryForm).Class("radio")
                                .Add(DeliveryForm.Pocztex, "Kurier Pocztex pobranie (+1zł)")
                                .Add(DeliveryForm.PersonalWithoutTraining, [
                                    "Odbiór osobisty bez szkolenia (+1zł)",
                                    new Select(place, ["Warszawa/Wola", "Warszawa/Mokotów"]).MarginLeft(12).WidthAuto()], x => { x.Label.MarginLeft(0) })
                                .Add(DeliveryForm.PersonalWithTraining, "Odbiór osobisty ze szkoleniem Warszawa/Wola (+51zł)")
                                .Add(DeliveryForm.Inpost, "Paczkomat Inpost (+1zł)", x => x.Disabled())
                                .Add(DeliveryForm.Rentomat, "Rentomat (+1zł)", x => x.Disabled())
                        ),
                        new Line(),
                        new InputRow("Płatność",
                            new Radio(paymentForm).Class("radio")
                                .Add(PaymentForm.Cash, "Gotówka (cała opłata przy odbiorze)", x => x.Enable(allowCashPayment))
                                .Add(PaymentForm.DepositPrepaidServicePostpaid, "Całościowa przedpłata kaucji przelewem, reszta przy odbiorze")
                                .Add(PaymentForm.PartialDepositPrepaidServicePostpaid, "Częściowa przedpłata kaucji przelewem, reszta przy odbiorze", x => x.Disabled())
                                .Add(PaymentForm.FromPreviousOrder, "Kaucja z poprzedniego zamówienia, płatność za usługę przy odbiorze", x => x.Disabled())
                                .Add(PaymentForm.Crypto, "Bitcoin (całość z góry, 10% rabatu)")
                                .Add(PaymentForm.Transfer, "Przelew (całość z góry)", x => x.Disabled())
                                .Visible(this.basket.IsAnythingToRentRef, "flex"),
                            new Radio(paymentForm).Class("radio")
                                .Add(PaymentForm.Cash, "Gotówka", x => x.Enable(allowCashPayment))
                                .Add(PaymentForm.CashAtDelivery, "Gotówka przy odbiorze, płatne u kuriera", x => x.Disable(allowCashPayment))
                                .Add(PaymentForm.Crypto, "Bitcoin (całość z góry, 10% rabatu)")
                                .Add(PaymentForm.Transfer, "Przelew (całość z góry)", x => x.Disabled())
                                .VisibleWhen(this.basket.IsAnythingToRentRef, v => !v, "flex")
                        ),
                        new Line().Visible(this.basket.IsAnythingToRentRef),
                        new InputRow("💰 Kaucja", new Label(depositInfo))
                            .Visible(basket.IsAnythingToRentRef, "flex"),
                        new Line(),
                        new InputRow("🎓 Szkolenie", new Label(trainingInfo)),
                    ]),
                    new Section("📦 Zwrot", [
                        new InputRow("Forma",
                            new Radio(returnForm).Class("radio")
                                .Add(ReturnForm.Package, "Przesyłka Inpost (na koszt nadawcy)")
                                .Add(ReturnForm.Personal, "Zwrot osobisty (bez kosztów)")
                                .Add(ReturnForm.Taxi, "Taxi (na koszt nadawcy; tylko w Warszawie)")
                                .Add(ReturnForm.Rentomat, "Rentomat (bez kosztów)", x => x.Disabled())
                        ),
                        new Line(),
                        new InputRow("Zwrot kaucji",
                            new Label(depositReturnInfo)
                                .VisibleWhen(showCustomerDepositAccountInput, v => !v),
                            new TextInput().Placeholder("Numer konta do zwrotu kaucji")
                                .Visible(showCustomerDepositAccountInput),
                        ),
                    ]).Visible(this.basket.IsAnythingToRentRef, "block"),
                    new Section("🤵 Twoje dane", [
                        new InputRow("Imię i nazwisko", new ValidableTextInput(customerName)),
                        new InputRow("Telefon", new ValidableTextInput(customerPhone, (v) => v.AddRule(x => x.length <= 9, "Too short"))),
                        new InputRow("Email", new ValidableTextInput(customerEmail)),
                        new InputRow("Adres", new ValidableTextInput(customerAddress).Placeholder("Ulica, numer budynku, numer mieszkania, kod pocztowy i miasto")),
                        new InputRow("Doświadczenie", new Select(customerExp, {
                            "Jestem osobą totalnie atechniczną": 1,
                            "Radzę sobie z technologią": 2,
                            "Znam podobny sprzęt": 3,
                            "Znam Wasz sprzęt": 4
                        })),
                    ]),
                    new Section("🎈 Rabaty", [
                        new InputRow("Kod rabatowy", new TextInput().Placeholder("Wpisz jeśli jakiś posiadasz")),
                    ]),
                    new Line(),
                    new Center(
                        new Button("Przejdź do podsumowania").OnClick(async () =>
                        {
                            summary.Clear()

                            summary.OrderId = uuidv4()

                            summary.Customer.Name = customerName.value;
                            summary.Customer.Phone = customerPhone.value;
                            summary.Customer.Email = customerEmail.value;
                            summary.Customer.Address = customerAddress.value;
                            summary.Customer.Experience = customerExp.value;

                            summary.DeliveryForm = deliveryForm.value;
                            summary.ReturnForm = returnForm.value;
                            summary.PaymentForm = paymentForm.value;

                            dynamicBasket.Items.ForEach(x =>
                            {
                                const item = new SummaryBasketItem()
                                item.Cost = x.Cost.value;
                                item.Product = x.Product;
                                item.Type = x.Type;
                                item.Quantity = x.Quantity.value;
                                item.RentStart = x.RentStart;
                                item.RentEnd = x.RentEnd;
                                summary.BasketItems.push(item)

                                summary.Add(new Cost(`${x.Label.value}: ${x.Product.Name} ×${x.Quantity.value}`, x.Cost.value))
                            })

                            let depositsSum = dynamicBasket.GetDepositsSum();
                            if (depositsSum)
                            {
                                summary.Add(new ReturnableCost("Kaucja zwrotna", depositsSum))
                            }

                            deliveryForm.Is(DeliveryForm.PersonalWithTraining)
                                ? summary.Add(new Cost("Szkolenie z obsługi", 50))
                                : summary.Add(new Cost("Dostawa", 1))

                            if (paymentForm.Is(PaymentForm.Crypto))
                            {
                                const cryptoDiscount = summary.NonReturnableCostsSum * 0.1;
                                summary.Add(new Discount("Zniżka za płatność w Bitcoin", cryptoDiscount))
                            }

                            view.value = OrderFormView.Summary;
                        }).Class("selected")
                    ).Margin(32)
                ))
                .AddContent(OrderFormView.Summary, () =>
                {
                    const returnToEditBtn = new PrimaryButton("Wróć do edycji").OnClick(() => view.value = OrderFormView.Summary).MarginLeft(8)

                    const loader = new Loader("Wysyłanie...").DisplayInline().Hide()

                    return new Div().Append(
                        new Section("🧮 Podsumowanie kosztów",
                            [
                                new Table([],
                                    [
                                        ...summary.Costs.map(x =>
                                        {
                                            if (x instanceof Discount)
                                                return ([x.Label, new Span("– " + x.Value.toFixed(2) + " zł").Color("blue")])
                                            else
                                                return ([x.Label, x.Value.toFixed(2) + " zł"])
                                        }),
                                    ]
                                ).Class("uk-table").WidthPercent(100).WidthAuto().MarginAuto().TextAlignLeft(),

                                new Markdown(`Łącznie do wpłaty: **${summary.Sum.toFixed(2)} zł**`)
                                    .DisplayBlock().Margin(0).MarginTop(24).MarginLeft(16),
                                basket.IsAnythingToRent && new Markdown(`Do zwrotu po oddaniu sprzętu: **${summary.ToReturn.toFixed(2)} zł**`)
                                    .DisplayBlock().Margin(0).MarginLeft(16),
                                paymentForm.Is(PaymentForm.Crypto) && new Markdown(`Złotówki zostaną przeliczone na Bitcoiny dopiero w kolejnym kroku.`).MarginLeft(32).Italic(),
                            ]),
                        new Section(basket.IsAnythingToRent ? "🚚 Dostawa i zwrot" : "🚚 Dostawa", [
                            new InputRow("Dostawa", DeliveryFormValueToLabel[deliveryForm.value]),
                            basket.IsAnythingToRent && new InputRow("Zwrot", ReturnFormValueToLabel[returnForm.value]),
                        ]),
                        basket.IsAnythingToRent && new Section("💰 Kaucja", [
                            new InputRow("Wpłata", depositInfo.value),
                            new InputRow("Zwrot", depositReturnInfo.value),
                        ]),
                        new Section("👦 Twoje dane", [
                            new InputRow("Imię i nazwisko", customerName.value),
                            new InputRow("Kontakt", customerPhone.value, ", ", customerEmail.value),
                            new InputRow("Adres", customerAddress.value),
                        ]),
                        new Line(),
                        new Center(
                            new DangerButton("Wyślij zgłoszenie").OnClick(async (btn) =>
                            {
                                btn.Disabled()
                                returnToEditBtn.Hide()
                                loader.Show()

                                const orderPackage = this.PreparePackage(summary);

                                let timeout;
                                try
                                {
                                    // timeout = setTimeout(() =>
                                    // {
                                    //     console.log('sending form timeout')
                                    //     view.value = OrderFormView.SendingFault;
                                    // }, 5 * 1000)

                                    console.log('creating...')
                                    const result = await this._repo.Create(orderPackage);

                                    view.value = result.IsSuccess ? OrderFormView.Sent : OrderFormView.SendingFault;
                                }
                                catch (error)
                                {
                                    console.log(error.message)
                                    view.value = OrderFormView.SendingFault;
                                }
                                finally
                                {
                                    console.log('finally')
                                    clearTimeout(timeout);
                                }

                                returnToEditBtn.Hide()
                                loader.Hide()
                            }),
                            loader,
                            returnToEditBtn,
                        ).Margin(32),
                    )
                })
                .AddContent(OrderFormView.Sent, () =>
                {
                    setTimeout(() =>
                    {
                        const ordersSystemWebsite = process.env.ORDERS_WEB?.replace("{orderId}", summary.OrderId) || "";
                        // window.location.href = `http://localhost:4339/#open/${summary.OrderId}`;
                        // basket.Clear();
                        console.log('moving to orders..')
                        window.location.href = ordersSystemWebsite;

                    }, 3 * 1000)

                    return new Center().Margin(32).Append(
                        "Zgłoszenie zostało wysłane. Za chwilę nastąpi przekierowanie na stronę zamówienia...")
                })
                .AddContent(OrderFormView.SendingFault, () =>
                {
                    return new Center().Margin(32).Append(
                        "Nie udało się wysłać zgłoszenia. Spróbuj ponownie za chwile.\n\n",
                        new Link("Wyślij ponownie").OnClick(() => view.value = OrderFormView.Summary)
                    )
                }),
        )
    }

    private PreparePackage(summary: BasketSummary): OrdersApiPackage
    {
        const ordersApiPackage = new OrdersApiPackage(summary.OrderId);

        ordersApiPackage.People
            .Clear()
            .Add({
                Type: HumanType.Customer,
                Name: summary.Customer.Name,
                Phone: summary.Customer.Phone,
                Address: summary.Customer.Address,
                Email: summary.Customer.Email,
                Experience: summary.Customer.Experience
            })

        ordersApiPackage.Basket.Clear()
        summary.BasketItems.forEach(x =>
        {
            let item: Partial<OrdersApiRawBasketItem> = {
                Type: x.Type,
                Product: x.Product,
                TotalPrice: x.Cost,
                Quantity: x.Quantity,
                Status: OrderBasketItemStatus.AwaitingConfirmation,
            }

            switch (x.Type)
            {
                case BasketItemType.Rent:
                    item = { ...item, Timeline: { Start: x.RentStart, End: x.RentEnd } } as Partial<OrdersApiRawRentOrder>;
                    break;
            }

            ordersApiPackage.Basket.Add(item)
        })

        let depositPaymentForm = PaymentForm.Unset;
        let depositReturnPaymentForm = PaymentForm.Unset;
        let basketPaymentForm = PaymentForm.Unset;
        let currency = "";

        switch (summary.PaymentForm)
        {
            case PaymentForm.Cash:
                depositPaymentForm = PaymentForm.Cash;
                depositReturnPaymentForm = summary.ReturnForm == ReturnForm.Personal ? PaymentForm.Cash : PaymentForm.Transfer;
                basketPaymentForm = PaymentForm.Cash;
                currency = "PLN";
                break;
            case PaymentForm.DepositPrepaidServicePostpaid:
                depositPaymentForm = PaymentForm.Transfer;
                depositReturnPaymentForm = PaymentForm.CashAtDelivery;
                basketPaymentForm = PaymentForm.Transfer;
                currency = "PLN";
                break;
            case PaymentForm.Crypto:
                depositPaymentForm = PaymentForm.Crypto;
                depositReturnPaymentForm = PaymentForm.Crypto;
                basketPaymentForm = PaymentForm.Crypto;
                currency = "BTC";
                break;
            default:
                throw new Error(`Unhandled payment form`);
        }

        ordersApiPackage.Payment
            .Clear()
            .Add({ Type: PaymentType.Deposit, Amount: summary.ReturnableCostsSum, Currency: currency, Form: depositPaymentForm, VisibleForCustomer: true, Status: PaymentStatus.Awaiting })
            .Add({ Type: PaymentType.Basket, Amount: summary.NonReturnableCostsSum, Currency: currency, Form: basketPaymentForm, VisibleForCustomer: true, Status: PaymentStatus.Awaiting })
            .Add({ Type: PaymentType.DepositReturn, Amount: summary.ReturnableCostsSum, Currency: currency, Form: depositReturnPaymentForm, VisibleForCustomer: true, Status: PaymentStatus.Quequed })

        const deliveryFormToTransit = {
            [DeliveryForm.Pocztex]: TransitType.Package,
            [DeliveryForm.Inpost]: TransitType.Package,
            [DeliveryForm.PersonalWithTraining]: TransitType.Personal,
            [DeliveryForm.PersonalWithoutTraining]: TransitType.Personal,
            [DeliveryForm.Rentomat]: TransitType.Rentomat,
        }
        const returnFormToTransit = {
            [ReturnForm.Package]: TransitType.Package,
            [ReturnForm.Personal]: TransitType.Personal,
            [ReturnForm.Taxi]: TransitType.Taxi,
            [ReturnForm.Rentomat]: TransitType.Rentomat,
        }
        ordersApiPackage.Transit
            .Clear()
            .Add({ Direction: TransitDirection.ToCustomer, Type: deliveryFormToTransit[summary.DeliveryForm], Status: TransitStatus.Preparing })
            .Add({ Direction: TransitDirection.FromCustomer, Type: returnFormToTransit[summary.ReturnForm], Status: TransitStatus.NotPlannedYet })

        if (summary.DeliveryForm == DeliveryForm.PersonalWithTraining)
        {
            ordersApiPackage.Training.Add({
                Type: TrainingType.Personal, Status: TrainingStatus.Awaits,
                Label: "Szkolenie odbędzie się podczas odbioru sprzętu"
            })
        }
        ordersApiPackage.Training.Add({
            Type: TrainingType.ViaTelephone, Status: TrainingStatus.Available,
            Label: "Pomoc techniczna ☎️ 507-293-714"
        })

        ordersApiPackage.Training.Clear()
        summary.BasketItems.forEach(x =>
        {
            ordersApiPackage.Training.Add({ Type: TrainingType.Self, Status: TrainingStatus.AvailableSoon, Label: `Materiały szkoleniowe dla ${x.Product.Name}`, Link: x.Product.Tutorial })
        })

        return ordersApiPackage;
    }
}
