import { Ref, RefString, RefDate, RefNumber } from "@tblabs/truffle";
import { BasketItemType } from "../Services/Basket/BasketItemType";
import { RawProduct } from "./Product";


// export interface RawStoredBasketItem
// {
//     Type: string;
//     ProductId: string;
//     From: Date;
//     To: Date;
//     Quantity: number;
//     RentPrices: { price: number, days: number }[];
// }

export interface RawBasketItem
{
    Type: string;
    ProductId: string;
    Quantity: number;
}

export interface RawSaleBasketItem extends RawBasketItem
{ }

export interface RawRentBasketItem extends RawBasketItem
{
    From: Date;
    To: Date;
}

// export class CustomerBasketItem
// {
//     public Type = new Ref<BasketItemType>(BasketItemType.Unset);
//     public ProductId = new RefString();
//     public From = new RefDate();
//     public To = new RefDate();
//     public Quantity = new RefNumber(1);
//     public RentPrices: { price: number, days: number }[] = [];

//     constructor(type?: BasketItemType, productId?: string, quantity = 1, from = new Date(0), to = new Date(0), rent = [])
//     {
//         if (type)
//             this.Type.value = type;
//         if (productId)
//             this.ProductId.value = productId;
//         this.Quantity.value = quantity;
//         this.From.value = from;
//         this.To.value = to;
//         this.RentPrices = rent;
//     }

//     public get TypeAsString(): string
//     {
//         return ({ [BasketItemType.Rent]: "Wypożyczenie", [BasketItemType.Sale]: "Zakup" }[this.Type.value] || "(nieznany typ)")
//     }
// }

export class ProductInfo
{
    public Origin: string = ""; // np "sklep specteam"
    public Id: string = ""; // id produktu w danym Originie
    public Name: string = ""; // np "Kamera ze słuchawką" z pola BasketName?
    public Link: string = ""; // "specteam.pl/product/1234-1234-1324"
    public Picture: string = "";
    public Tutorial: string = "";
    public Price: string = "";
    public Details: string = "";
    public Tags: object = {};
}

export abstract class BasketItem
{
    public Type = BasketItemType.Unset;
    public ProductId = new RefString();
    public Quantity = new RefNumber(1);
    public Product: ProductInfo;

    public abstract TypeAsString: string;

    constructor(productId?: string, quantity = 1)
    {
        if (productId)
            this.ProductId.value = productId;
        this.Quantity.value = quantity;
    }
}

export class SaleBasketItem extends BasketItem
{
    constructor(raw: Partial<RawSaleBasketItem>)
    {
        super(raw.ProductId, raw.Quantity);

        this.Type = BasketItemType.Sale;
    }

    public get TypeAsString(): string
    {
        return "Zakup";
    }
}

export class RentBasketItem extends BasketItem
{
    public From = new RefDate();
    public To = new RefDate();

    constructor(raw: Partial<RawRentBasketItem>)
    {
        super(raw.ProductId, raw.Quantity);

        this.Type = BasketItemType.Rent;
        this.From.value = raw.From || new Date(0);
        this.To.value = raw.To || new Date(0);
    }

    public get TypeAsString(): string
    {
        return "Wypożyczenie";
    }
}
