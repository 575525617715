import { RefString, RefNumber } from "@tblabs/truffle";
import { ProductInfo } from "../Models/CustomerBasketItem";


export class DynamicBasketItem
{
    public Type = "";
    public Product = new ProductInfo();
    
    public Label = new RefString();
    public Deposit = 0;

    public Quantity = new RefNumber();
    public DiscountLabel = new RefString();
    public Cost = new RefNumber();
    
    // For Rent type
    public RentStart: Date;
    public RentEnd: Date;
}
