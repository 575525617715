
export enum PaymentForm
{
    Unset = "Unset",
    Cash = "Cash",
    Transfer = "Transfer",
    Crypto = "Crypto",
    DepositPrepaidServicePostpaid = "DepositPrepaidServicePostpaid",
    PartialDepositPrepaidServicePostpaid = "PartialDepositPrepaidServicePostpaid",
    FromPreviousOrder = "FromPreviousOrder",
    CashAtDelivery = "CashAtDelivery"
}


export enum BasketItemType
{
    Unset = "Unset",
    Rent = "Rent",
    Sale = "Sale",
    Service = "Service",
    Help = "Help"
}
