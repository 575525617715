import { Div, Link, Image, Span, TableBuilder, NumberInput } from "@tblabs/truffle";
import { Center } from "./Utils/Center";
import { Basket } from "../Services/Basket/Basket";


export class MiniBasketBox extends Div
{
    constructor(private _basket: Basket)
    {
        super();
    }

    protected async OnAppend(): Promise<void>
    {
        const content = await this._basket.Content.Get();

        this.Append(
            new TableBuilder(this._basket.Products)
                .AddColumn("", x => x.TypeAsString)
                .AddColumn("", p =>
                {
                    const product = content.Products.Items.find(x => x.Id == p.ProductId.value);
                    const productName = product?.BasketName.value || "(produkt już nie istnieje)";
                    const productPhoto = product?.Images?.Items?.[0]?.ThumbnailUrl.value || "";
                    return new Div().Append(
                        new Image(productPhoto).Height(36).Width(62).Border(2, "#fff"),
                        new Span(productName).FontSize(14).MarginLeft(8))
                })
                .AddColumn("", x => new NumberInput(x.Quantity).Min(1).Width(56))
                .AddColumn("", x => new Link("❌").NoDecorationOnHover().OnClick(() => this._basket.Remove(x)))
                .WhenEmpty(new Center("Nie dodano jeszcze produktów").MarginTopBottom(32))
                .Build(),
        )
    }
}
