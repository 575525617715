import { Collection, Ref, RefNumber } from "@tblabs/truffle";
import { WebsiteContent } from "../Models/WebsiteContent";
import { BasketItemType } from "../Services/Basket/BasketItemType";
import { DynamicBasketItem } from "./DynamicBasketItem";
import { RentPriceCalculator } from "./RentPriceCalculator";
import { Basket } from "../Services/Basket/Basket";
import { DatesRange } from "./DatesRange";


export class DynamicBasket
{
    public Items = new Collection<DynamicBasketItem>();
    public Sum = new RefNumber();
    public ItemsToRentCount = new RefNumber();

    constructor(private content: WebsiteContent, private basket: Basket, private rentTime: DatesRange)
    {
        basket.Products.OnChange(_ => this.Recalc());
        rentTime.DaysCount.OnChange(_ => this.Recalc());
        this.Recalc();
    }

    public Remove(dynamicItem: DynamicBasketItem): void
    {
        const basketItem = this.basket.Products.Items.find(x => x.ProductId.value == dynamicItem.Product.Id);
        if (!basketItem) return;
        this.basket.Remove(basketItem)
        this.Recalc();
    }

    private Recalc()
    {
        this.Items.Clear();
        this.Sum.value = 0;
        this.ItemsToRentCount.value = 0;

        this.basket.Products.Items.forEach(i =>
        {
            const product = this.content.Products.Items.find(x => x.Id == i.ProductId.value);
            if (!product) return;
          
            const productRentPrices = product.RentPrices.Items.filter(x => x.Visible.value).map(x => ({ price: x.Value.value, days: x.Days.value }));
           
            const bi = new DynamicBasketItem();
            bi.Type = i.Type;
            bi.RentStart = this.rentTime.Start.value;
            bi.RentEnd = this.rentTime.End.value;
            bi.Product.Id = i.ProductId.value;
            bi.Deposit = product.Deposit.value;
            bi.Product.Picture = product.Images.Items[0].ThumbnailUrl.value;
            bi.Product.Name = product.BasketName.value;
            bi.Product.Link = "#product/" + product.Url.value;
            bi.Product.Price = i.Type == BasketItemType.Rent
                ? productRentPrices.map(x => `${x.price}zł/${x.days}dni`).join(", ")
                : product.SalePrice.value + "zł brutto";
            bi.Label.value = i.TypeAsString + (i.Type == BasketItemType.Rent ? ` na ${this.rentTime.DaysCount.value} ${(this.rentTime.DaysCount.value == 1 ? "dzień" : "dni")}` : "");
            bi.Quantity = i.Quantity;
            bi.Quantity.OnChange(q =>
            {
                let discount = 0;
                if (i.Type == BasketItemType.Rent)
                {
                    this.ItemsToRentCount.value += 1;
                    switch (q)
                    {
                        case 1: discount = 0; break;
                        case 2: discount = 10; break;
                        case 3:
                        case 4:
                        case 5: discount = 20; break;
                        default: discount = 30; break;
                    }
                }
                if (i.Type == BasketItemType.Sale)
                {
                    switch (q)
                    {
                        case 1: discount = 0; break;
                        case 2: discount = 3; break;
                        case 3: discount = 5; break;
                        case 4: discount = 7; break;
                        case 5: discount = 10; break;
                        default: discount = 14; break;
                    }
                }
                bi.DiscountLabel.value = `${discount}% rabatu`;
                const discountFactor = (100 - discount) / 100;
                bi.Cost.value = i.Type == BasketItemType.Rent
                    ? Math.round(i.Quantity.value * discountFactor * RentPriceCalculator.Calc(productRentPrices, this.rentTime.DaysCount.value))
                    : Math.round(i.Quantity.value * discountFactor * product.SalePrice.value);

                this.Sum.value += bi.Cost.value;
            }, true);

            this.Items.Add(bi);
        });
    }

    public GetDepositsSum(): number
    {
        let depositsSum = 0;
        this.Items.Items.filter(item =>
        {
            if (item.Type == BasketItemType.Rent)
                depositsSum += item.Deposit;
        })
        return depositsSum;
    }
}
