

export enum TransitStatus
{
    Unset = "Unset",
    NotPlannedYet = "NotPlannedYet",
    Planned = "Planned",
    Preparing = "Preparing",
    InTransit = "InTransit",
    Delivered = "Delivered",
    NotDelivered = "NotDelivered",
    Lost = "Lost",
    Failed = "Failed",
    Changed = "Changed",
    Returned = "Returned"
}
